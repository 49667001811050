<template>
    <div class="ts-table flex flex-item flex-column">
        <ts-table-edit ref="table" class="make-table flex-item" :cols="cols" :data="list" :page-param="pageParam"
            :loading="loading" @handleSort="sort" full :cmds="cmds" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" @setSearchClose="setSearchClose"
            @setHandleFilter="setHandleFilter" :searchParam="searchParam" :table-name="tableName"
            :isSearchLeft="isSearchLeft" :searchCols="searchCols">

            <div class="page-right" slot="page">
                <div class="static-data page" v-if="staticData.ownNumber">
                    本人已提交：<div class="red">{{ staticData.ownNumber }}</div>审核通过：<div class="red">
                        {{ staticData.ownPassNumber }}</div>获得积分：<div class="red">{{ staticData.integralNumber }}</div>
                </div>
            </div>
        </ts-table-edit>

        <edit-drawer :show.sync="dialog.show" :loading="dialog.loading" @save="save" v-if="dialog.show" :rich="rich"
            :dialog="dialog" :title="title + (dialog.edit ? '修改' : '新增')" :cols="cols"
            :form="dialog.form"></edit-drawer>
    </div>
</template>

<script>
import TsTableEdit from './TsTableEdit'
import EditDrawer from './EditDrawer'
import commonMixin from './commonMixin'
import Vue from 'vue'
export default {
    props: ['visitId', 'searchParam'],
    components: { TsTableEdit, EditDrawer },
    mixins: [commonMixin],
    data() {
        return {
            tableName: 'base_comp_news',
            api: {
                page: 'sys/baseCompNews/queryBaseCompNewsPage',
                add: 'sys/baseCompNews/insertBaseCompNews',
                modify: 'sys/baseCompNews/updateBaseCompNews',
                del: 'sys/baseCompNews/deleteById',
                static: 'sys/baseCompNews/staticArticle',
                exist: 'sys/baseCompNews/haveSame',
            },
            staticCols: [
                {
                    field: 'compNumber',
                    title: '企业',
                    showField: 'compName',
                },
                {
                    field: 'yearNumber',
                    title: '年份',
                    showField: 'year',
                },
            ],
            cols: [
                {
                    key: 'compCode',
                    showKey: 'compName',
                    searchKey: 'compName',
                    value: '企业',
                    type: 'fuzzy',
                    search: true,
                    queryType: this.$variable.searchType.SELECT,
                    required: true,
                    param: {
                        api: 'setting/aimBaseComp/queryAimBaseCompListByKeyword',
                        keyField: 'compCode',
                        valueField: 'compName',
                        assignment(formObj, toObj) {
                            Vue.set(toObj, 'compCode', formObj.compCode)
                            Vue.set(toObj, 'compName', formObj.compName)
                        },
                    }
                },
                { key: 'title', value: '标题', required: true, search: true, width: 150 },
                { key: 'publishDate', value: '发布日期', required: true },
                { key: 'author', value: '作者', search: true ,required: true,},
                { key: 'department', value: '部门', search: true },
                { key: 'articleKeyword', value: '关键字', search: true },
                { key: 'href', value: '链接' },
                { key: 'contentText', value: '内容', type: 'rich', },
                { key: 'failReason', value: '不通过原因', width: 200, hEdit: true },
                {
                    width: 120,
                    key: 'status',
                    showKey: 'statusName',
                    value: '审核状态',
                    fixed: 'right',
                    hEdit: true
                },
            ],
            existParam: {
                colKeys: ['title', 'compCode', 'publishDate', 'author'],
            },
            rich: {
                key: 'contentText',
            },
            cmds: [
                {
                    txt: '修改',
                    type: 'primary',
                    event: this.modify,
                    whether(row) {
                        return row.status !== 1
                    },
                },
                {
                    txt: '删除',
                    type: 'danger',
                    event: this.del,
                    whether(row) {
                        return row.status !== 1
                    },
                }
            ],
        }
    },
    watch: {
        'dialog.form.title'() {
            this.isExist()
        },
        'dialog.form.compCode'() {
            this.isExist()
        },
        'dialog.form.publishDate'() {
            this.isExist()
        },
        'dialog.form.author'() {
            this.isExist()
        },
    }
}
</script>